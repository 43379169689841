import { Injectable } from '@angular/core';
import { AppUpdate, AppUpdateInfo } from '@capawesome/capacitor-app-update';
import { AlertController, AlertOptions } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppUpdateService {
  constructor(private alertController: AlertController) {}

  public init(forceUpdate = false) {
    if (!environment.production) {
      return;
    }

    AppUpdate.getAppUpdateInfo({ country: 'US' }).then((appUpdateInfo) => {
      this.checkForUpdate(appUpdateInfo, forceUpdate);
    });
  }

  private checkForUpdate(appUpdateInfo: AppUpdateInfo, forceUpdate: boolean) {
    const localVersion = appUpdateInfo.currentVersion;
    const storeVersion = appUpdateInfo.availableVersion;

    let alertContent = {
      id: 'app-update-alert',
      header: 'Update Available',
      message: 'Open the app store to update your Laundry Pro app',
      buttons: [
        {
          id: 'app-update-cancel',
          text: 'Cancel',
          role: 'cancel',
        },
        {
          id: 'app-update-ok',
          text: 'App Store',
          role: 'ok',
          handler: () => {
            this.openAppStore();
          },
        },
      ],
    };

    if (forceUpdate) {
      alertContent = {
        id: 'app-update-alert',
        header: 'Update Available',
        message: 'Open the app store to update your Laundry Pro app',
        buttons: [
          {
            id: 'app-update-ok',
            text: 'App Store',
            role: 'ok',
            handler: () => {
              this.openAppStore();
            },
          },
        ],
      };
    }

    if (localVersion < storeVersion) {
      this.showUpdateAlert(alertContent);
    }
  }

  private async openAppStore(): Promise<void> {
    await AppUpdate.openAppStore();
  }

  private async showUpdateAlert(options: AlertOptions): Promise<void> {
    await this.alertController.create();
    const alert = await this.alertController.create(options);
    return await alert.present();
  }
}
