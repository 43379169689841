import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { UntilDestroy } from '@ngneat/until-destroy';
import { lastValueFrom, Observable, ReplaySubject } from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import statsig, { DynamicConfig } from 'statsig-js';
import { SudsterData } from '../_interfaces/sudster-data.interface';
import { AuthidService } from './authid.service';

@Injectable({
  providedIn: 'root',
})
@UntilDestroy()
export class StatsigService {
  public Initialize$ = new ReplaySubject<boolean>(1);

  constructor(
    private firestore: AngularFirestore,
    private AuthID: AuthidService
  ) {
    this.Initialize$.next(false);
    this.AuthID.userID$
      .pipe(
        filter((userId) => !!userId),
        take(1),
        mergeMap((userId) =>
          this.firestore.doc<SudsterData>(`Sudsters/${userId}`).get()
        )
      )
      .subscribe((userDoc) => {
        if (userDoc.exists) {
          userDoc.id;
          this.initializeStatsig(userDoc.id, userDoc.data())
            .then(() => this.setStatsigInitialized())
            .catch((e) => {
              console.error('Error initializing statsig', e);
            });
        }
      });
  }
  async initializeStatsig(uid: string, user: SudsterData): Promise<void> {
    // initialize returns a promise which always resolves
    let state = '';
    if (user) {
      if (user.State) {
        const stateRegex = /[^,]*[A-Z]{2}/;
        const stateResult = user.State.match(stateRegex);
        if (stateResult && stateResult.length > 0) {
          state = stateResult[0].trim();
        }
      }
    }
    const statsigUser = {
      custom: {
        state,
      },
      userID: uid,
    };
    return await statsig.initialize(environment.statsig.clientKey, statsigUser);
  }

  setStatsigInitialized() {
    this.Initialize$.next(true);
  }

  checkGate(gateName: string): Observable<boolean> {
    return this.Initialize$.pipe(
      filter((initialized) => {
        return initialized === true;
      }),
      take(1),
      map(() => {
        return statsig.checkGate(gateName);
      })
    );
  }

  /** Prefer to use the one that returns a Promise as this depends on the sdk to be initialized */
  getConfig(configName) {
    return statsig.getConfig(configName);
  }

  getConfigPromise(configName): Promise<DynamicConfig> {
    return lastValueFrom(
      this.Initialize$.pipe(
        filter((initialized) => {
          return initialized === true;
        }),
        take(1),
        map(() => {
          return statsig.getConfig(configName);
        })
      )
    );
  }

  async shutdownPromisify(): Promise<void> {
    await lastValueFrom(
      this.Initialize$.pipe(
        take(1),
        map((value) => {
          if (value) {
            statsig.shutdown();
            this.Initialize$.next(false);
          }
        })
      )
    );
  }

  shutdown() {
    statsig.shutdown();
    this.Initialize$.next(false);
  }
}
