import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { catchError, concatMap, map, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';

export enum ApiResponseStatus {
  ok = '200',
  created = '201',
  noContent = '204',
  badRequest = '400',
  unauthenticated = '401',
  notFound = '404',
  serverError = '500',
}

export class ApiResponse {
  status: ApiResponseStatus;
  message: string;
  data: Record<string, any> | string | null;
  rawResponse: Response;

  constructor(status: ApiResponseStatus, data = null, message = '') {
    this.status = status;
    this.message = message;
    this.data = data;
  }
  setRawResponse(response: Response) {
    this.rawResponse = response;
  }
}

export class ApiResponseError extends ApiResponse implements Error {
  name: string;
  stack?: string;
  constructor(status: ApiResponseStatus, data, message) {
    super(status, data, message);
    this.name = status;
  }
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  baseEndpoint: string;
  constructor(private afAuth: AngularFireAuth) {
    this.baseEndpoint = environment.baseEndpoint;
  }

  public post(
    endpointPath: string,
    payload: Record<string, any>
  ): Observable<ApiResponse> {
    const defaultHeaders = {
      'Content-Type': 'application/json',
      Authorization: '',
    };

    return this.afAuth.idToken.pipe(
      take(1), // use the latest jwt token in the service on every request,+handles the subs
      map((idToken) => {
        if (!idToken) {
          // can be null.
          throw new ApiResponseError(
            ApiResponseStatus.unauthenticated,
            null,
            'User is not authenticated'
          );
        }
        const headers = new Headers({
          ...defaultHeaders,
          Authorization: `Bearer ${idToken}`,
        });
        return headers;
      }),
      concatMap((headers) => {
        return fromFetch(this.baseEndpoint + endpointPath, {
          method: 'POST',
          headers,
          body: JSON.stringify(payload),
        });
      }),
      map((response) => {
        let status = ApiResponseStatus.ok;
        if (!response.ok) {
          status =
            response.status == 404
              ? ApiResponseStatus.notFound
              : ApiResponseStatus.badRequest;
        }
        const res = new ApiResponse(status, response.json());
        res.setRawResponse(response);
        return res;
      }),
      catchError((err) => {
        // do global logic with the error message, like when no session force logout.
        if (err instanceof ApiResponseError) {
          return of(err);
        } else {
          return of(
            new ApiResponseError(
              ApiResponseStatus.badRequest,
              null,
              err.message
            )
          );
        }
      })
    );
  }

  public get(endpointPath: string): Observable<ApiResponse> {
    const defaultHeaders = {
      'Content-Type': 'application/json',
      Authorization: '',
    };

    return this.afAuth.idToken.pipe(
      take(1), // use the latest jwt token in the service on every request,+handles the subs
      map((idToken) => {
        if (!idToken) {
          // can be null.
          throw new ApiResponseError(
            ApiResponseStatus.unauthenticated,
            null,
            'User is not authenticated'
          );
        }
        const headers = new Headers({
          ...defaultHeaders,
          Authorization: `Bearer ${idToken}`,
        });
        return headers;
      }),
      concatMap((headers) => {
        return fromFetch(this.baseEndpoint + endpointPath, {
          method: 'GET',
          headers,
        });
      }),
      map((response) => {
        let status = ApiResponseStatus.ok;
        if (!response.ok) {
          status =
            response.status == 404
              ? ApiResponseStatus.notFound
              : ApiResponseStatus.badRequest;
        }
        const res = new ApiResponse(status, response.json());
        res.setRawResponse(response);
        return res;
      }),
      catchError((err) => {
        // do global logic with the error message, like when no session force logout.
        if (err instanceof ApiResponseError) {
          return of(err);
        } else {
          return of(
            new ApiResponseError(
              ApiResponseStatus.badRequest,
              null,
              err.message
            )
          );
        }
      })
    );
  }
  public patch(
    endpointPath: string,
    payload: Record<string, any>
  ): Observable<ApiResponse> {
    const defaultHeaders = {
      'Content-Type': 'application/json',
      Authorization: '',
    };

    return this.afAuth.idToken.pipe(
      take(1), // use the latest jwt token in the service on every request,+handles the subs
      map((idToken) => {
        if (!idToken) {
          // can be null.
          throw new ApiResponseError(
            ApiResponseStatus.unauthenticated,
            null,
            'Missing authentication token'
          );
        }
        const headers = new Headers({
          ...defaultHeaders,
          Authorization: `Bearer ${idToken}`,
        });
        return headers;
      }),
      concatMap((headers) => {
        return fromFetch(this.baseEndpoint + endpointPath, {
          method: 'PATCH',
          headers,
          body: JSON.stringify(payload),
        });
      }),
      map((response) => {
        const status = ApiResponseStatus.ok;
        const res = new ApiResponse(status, response.json());
        res.setRawResponse(response);
        return res;
      }),
      catchError((err) => {
        // do global logic with the error message, like when no session force logout.
        if (err instanceof ApiResponseError) {
          return of(err);
        } else {
          return of(
            new ApiResponseError(
              ApiResponseStatus.serverError,
              null,
              err.message
            )
          );
        }
      })
    );
  }
}
