import { ErrorHandler, Inject, Injectable } from '@angular/core';
import Rollbar from 'rollbar';
import { RollbarService } from '../rollbar/rollbar.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlingService implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err: any): void {
    this.rollbar.error(err);
  }
}
