import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AFConstants, AFInit, AppsFlyer } from 'appsflyer-capacitor-plugin';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthidService } from './authid.service';
import { DeepLinkService } from './deep-link.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AppsFlyerService {
  private initialized = false;

  constructor(
    public platform: Platform,
    private AuthID: AuthidService,
    private deepLinkService: DeepLinkService
  ) {
    this.platform.ready().then(async () => {
      if (this.initialized) {
        return;
      }

      const afConfig: AFInit = {
        appID: environment.appsflyer.appId,
        devKey: environment.appsflyer.devKey,
        isDebug: !environment.production,
        waitForATTUserAuthorization: 10,
        minTimeBetweenSessions: 6,
        registerOnDeepLink: true,
        registerConversionListener: true,
        registerOnAppOpenAttribution: false,
        deepLinkTimeout: 2000,
        useReceiptValidationSandbox: true,
        useUninstallSandbox: false,
      };

      AppsFlyer.setResolveDeepLinkURLs({
        urls: [environment.appsflyer.emailLinkWrapper],
      });

      AppsFlyer.setOneLinkCustomDomain({
        domains: [environment.appsflyer.oneLink],
      });

      AppsFlyer.addListener(AFConstants.OAOA_CALLBACK, (event) => {
        if (event.callbackName === AFConstants.onAppOpenAttribution) {
          const reconstructedUrl = `https://${event.data.host}/${
            event.data.deep_link_value.startsWith('/')
              ? event.data.deep_link_value.substring(1)
              : event.data.deep_link_value
          }`;
          this.navigateWhenLoggedIn(reconstructedUrl);
        }
      });

      AppsFlyer.addListener(AFConstants.CONVERSION_CALLBACK, (event) => {
        if (event.callbackName === AFConstants.onConversionDataSuccess) {
          if (event.data.af_status === 'Non-organic') {
            if (event.data.is_first_launch === true) {
              const reconstructedUrl = `https://${event.data.host}/${
                event.data.deep_link_value.startsWith('/')
                  ? event.data.deep_link_value.substring(1)
                  : event.data.deep_link_value
              }`;
              this.navigateWhenLoggedIn(reconstructedUrl);
            }
          }
        }
      });

      AppsFlyer.addListener(AFConstants.UDL_CALLBACK, (res) => {
        if (
          res.status === 'FOUND' &&
          (res.deepLink?.deep_link_value || res.deepLink?.link)
        ) {
          const deepLinkValue: string = res.deepLink.deep_link_value || '';
          let reconstructedUrl = `https://${res.deepLink.host}/${
            deepLinkValue.startsWith('/')
              ? deepLinkValue.substring(1)
              : deepLinkValue
          }`;
          if (res.deepLink.link && res.deepLink.link.startsWith('poplinlp:')) {
            // custom uri scheme ios
            reconstructedUrl = res.deepLink.link;
          }
          this.navigateWhenLoggedIn(reconstructedUrl);
        }
      });

      await AppsFlyer.initSDK(afConfig)
        .then((res) => {
          this.initialized = true;
        })
        .catch((e) => console.error('AppsFlyer Exception: ', e));

      this.AuthID.lastDistinctUserID$
        .pipe(untilDestroyed(this), take(1))
        .subscribe((userId) => {
          this.setLpId(userId);
        });
    });
  }

  async setLpId(userId: string): Promise<void> {
    return await AppsFlyer.setCustomerUserId({ cuid: userId }).catch((e) =>
      console.error('AppsFlyer Exception setCustomerId: ', e)
    );
  }

  private navigateWhenLoggedIn(reconstructedUrl: string) {
    this.AuthID.lastDistinctUserID$
      .pipe(untilDestroyed(this), take(1))
      .subscribe(() => {
        this.deepLinkService.handleUrl(reconstructedUrl);
      });
  }
}
