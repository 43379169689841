const firebase = {
  apiKey: 'AIzaSyBKHhl6I2xPGNjFaxv-gFZuFOSSTae3q2Y',
  authDomain: 'sudsharetest.firebaseapp.com',
  databaseURL: 'https://sudsharetest.firebaseio.com',
  projectId: 'sudsharetest',
  storageBucket: 'sudsharetest.appspot.com',
  messagingSenderId: '119070405931',
  appId: '1:119070405931:web:ff33db9af7d936d3ce016e',
  measurementId: 'G-XJ5SR9E3S1',
};

export const environment = {
  production: false,
  emulator: false,
  version: '3.49.0',
  baseEndpoint: `https://us-central1-${firebase.projectId}.cloudfunctions.net/`,
  apiGateway: 'https://non-prod-1ip7g17v.uc.gateway.dev',
  apiToken: 'AIzaSyDh1zGEIVsu0MJKzzIU3n6wC3fmfqKu_mE',
  firebase,
  GoogleMaps: 'AIzaSyCL_QcXwq2sY0ZiLBT7rBlMpVQ9mcMpGks',
  Stripe: 'pk_test_UN2Hmj2I2XyW9cSoQvuYriao',
  webApp: 'https://nonprod-laundry-pro.poplin.co',
  apiPath: 'https://non-prod-1ip7g17v.uc.gateway.dev',
  statsig: {
    clientKey: 'client-m7UNwYRjZPJJU9wEH9KqVlEGBs5OTicQaAGP0uqbrlv',
    flags: {
      testFlag: 'testflag',
      forterLoginApi: 'forterloginapi',
      orderExtension: 'orderextension',
      stripeIdv: 'stripeidv',
      checkrVerification: 'checkrverification',
      showOrderDataPreacceptance: 'showorderdatapreacceptance',
      ForceUpdate: 'sudsterappupdate',
      SudsterReferralCode: 'sudsterreferralcode',
      overweightOrderExtension: 'overweightorderextension',
      smartlookRecordings: 'smartlookrecording',
      ExpressService: 'expressservice',
      LPFirstOrderPickUpDeadline: 'lpfirstorderpickupdeadline',
    },
    dynamic: {
      orderExtensionBagCount: 'orderextensionbagcount',
      referralLpABTest: 'lp-referral-ab-test',
    },
  },
  checkr: {
    endpoint: 'https://api.checkr-staging.com/v1',
    apiKey: 'c66506832d504e12f1bf9991ec67b72dcbed6803',
    package: 'test_pro_criminal',
  },
  zendesk: {
    iosKey:
      'eyJzZXR0aW5nc191cmwiOiJodHRwczovL3BvcGxpbi56ZW5kZXNrLmNvbS9tb2JpbGVfc2RrX2FwaS9zZXR0aW5ncy8wMUg3R0hBQjRBODg2VjlOTUhXRzlLNkJTOS5qc29uIn0=',
    androidKey:
      'eyJzZXR0aW5nc191cmwiOiJodHRwczovL3BvcGxpbi56ZW5kZXNrLmNvbS9tb2JpbGVfc2RrX2FwaS9zZXR0aW5ncy8wMUg3R0g5UDRQUFY4MTNQQVlOTVA1WDlYMS5qc29uIn0=',
    webKey: '2d51a9d5-994f-4529-8a8b-664e60999006',
  },
  smartlook: {
    key: 'cfd9d9ab7d4834f745509d206172340d0c2ce088',
  },
  appsflyer: {
    appId: 'id1333000000',
    devKey: 'qSjkjhkjsiiiiiiiii',
    oneLink: 'appsflyer.poplin.co',
    emailLinkWrapper: 'click.poplin.co',
  },
  rollbar: {
    accessToken: 'f640e49d7ba9430793829c8ef364d54e',
  },
};
