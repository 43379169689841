import { Injectable } from '@angular/core';
import { registerPlugin } from '@capacitor/core';
import { AuthidService } from '../authid.service';
import { DeepLinkService } from '../deep-link.service';
import { IterablePlugin } from '../plugin-definitions';

const iterablePlugin = registerPlugin<IterablePlugin>('Iterable', {
  web: () =>
    import('./iterable-web.plugin').then(
      (m) => new m.IterablePluginWeb(window)
    ),
});

@Injectable({
  providedIn: 'root',
})
export class IterableService {
  constructor(
    private AuthID: AuthidService,
    private deepLinkService: DeepLinkService
  ) {
    this.AuthID.userID$.subscribe((userId) => {
      if (userId) {
        this.registerUser(userId);
      }
    });

    iterablePlugin.addListener('IterableInAppOnUrl', (data) => {
      this.deepLinkService.handleUrl(data.url);
    });
  }

  registerUser(userId: string) {
    return iterablePlugin.registerUser({ userId });
  }

  trackPush(
    templateId: number,
    campaignId: number,
    msgId: string,
    appWasRunning: boolean
  ) {
    return iterablePlugin.trackPush({
      templateId,
      campaignId,
      msgId,
      appWasRunning,
    });
  }
}
