import { Injectable, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CookieService } from 'ngx-cookie-service';
import { fromEvent, merge, Observable, of, Subscription } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';

export interface ForterResult {
  token: string;
  type: 'mobile' | 'web';
}
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ForterService implements OnDestroy {
  private tokenResult$: Observable<ForterResult>;
  constructor(private window: Window, private cookieService: CookieService) {
    // Check if existing value on window.forterKey and
    // listen to custom event 'forter-token-init' from window and cache 1 value for late subscriptions.
    this.tokenResult$ = merge(
      of(this.window['forterKey']),
      fromEvent(window, 'forter-token-init')
    ).pipe(
      untilDestroyed(this),
      filter((evt) => !!evt),
      map((evt: CustomEvent) => {
        return {
          token: evt.detail.token,
          type: evt.detail.web ? 'web' : 'mobile',
        } as ForterResult;
      }),
      shareReplay(1)
    );
    // forter cookie may already exist on the document.cookie element
    // and or web sdk may not trigger any event.
    const forterCookieToken = this.cookieService.get('forterToken');
    if (forterCookieToken) {
      const e = new CustomEvent('forter-token-init', {
        detail: {
          token: forterCookieToken,
          type: 'web',
        },
      });
      window.dispatchEvent(e); // any new token change will be notified
    }
  }
  ngOnDestroy(): void {}
  onTokenChange(callback: (ForterResult) => void): Subscription {
    return this.tokenResult$.subscribe(callback);
  }
}
