import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Capacitor, registerPlugin } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { NavController } from '@ionic/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ReplaySubject } from 'rxjs';
import { distinct, filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';
import { ForterResult, ForterService } from './forter.service';
import { SudSharePlugin } from './plugin-definitions';

const SharedPlugin = registerPlugin<SudSharePlugin>('SudSharePlugin');
const isSudSharePluginAvailable = Capacitor.isPluginAvailable('SudSharePlugin');

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AuthidService implements OnDestroy {
  UserID: string;
  userID$ = new ReplaySubject<string | null>(1);
  lastDistinctUserID$ = this.userID$.pipe(
    distinct(),
    filter((userId) => !!userId)
  );
  latestForterToken: ForterResult | null = null;
  private mobileId;
  private platformInfo;

  constructor(
    public afAuth: AngularFireAuth,
    private cloudFunctions: AngularFireFunctions,
    private forterService: ForterService,
    private firestore: AngularFirestore,
    private navCtrl: NavController,
    private apiService: ApiService
  ) {
    Device.getInfo().then((info) => {
      this.platformInfo = info.platform;
    });

    Device.getId().then((deviceId) => {
      this.mobileId = deviceId.identifier;
    });

    // as soon as the service is created, tries to pull out member ID
    this.afAuth.user
      .pipe(untilDestroyed(this), distinct())
      .subscribe((user) => {
        this.UserID = user ? user.uid : '';
        this.userID$.next(user ? user.uid : null);
        if (isSudSharePluginAvailable && this.UserID) {
          SharedPlugin.setup({ userId: this.UserID }).catch((e) => {
            console.error('Exception with SudSharePlugin plugin', e);
          });
        }
      });

    this.forterService.onTokenChange((tokenResult: ForterResult) => {
      this.latestForterToken = tokenResult;
      // Consider a low probility but existing case where you will be notified with bot 'web', 'mobile' tokens
      // 'mobile' should have priority over 'web'
    });
  }
  ngOnDestroy(): void {
    this.userID$.complete();
  }

  activate() {
    return new Promise((resolve) => {
      const userAuthObs = this.afAuth.user.subscribe((user) => {
        if (user) {
          this.UserID = user.uid;
          resolve(this.UserID);
        } else {
          this.UserID = '';
          resolve(this.UserID);
        }
      });
    });
  }
  // Create function that collects data for collectionInformation
  getID() {
    return this.UserID;
  }

  async callForterAPI(
    userID: string,
    callType: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    status?: string,
    statusChangeReason?: string,
    statusChangeBy?: string
  ) {
    if (!this.latestForterToken) {
      return;
    }

    return new Promise((resolve) => {
      const { type, token } = this.latestForterToken;
      this.cloudFunctions
        .httpsCallable('RunForterValidation')({
          forterData: {
            accountId: userID,
            eventTime: new Date().valueOf(),
            forterTokenCookie: type == 'web' ? token : '',
            forterMobileUID: type == 'mobile' ? token : '',
            rememberThisDevice: true,
            additionalInformation: {},
            type: 'AGENT',
            firstName: firstName,
            lastName: lastName,
            email: email,
            status: status,
            statusChangeReason: statusChangeReason,
            statusChangeBy: statusChangeBy,
          },
          callType: callType,
          repo: 'Sudsters/',
        })
        .toPromise()
        .then((res) => {
          if (res.forterDecision == 'DECLINE') {
            // IF FORTER REJECTS, SET ACCOUNT REJECTED TO TRUE AND NAVIGATE TO REJECTED PAGE
            this.firestore.doc(`Sudsters/${this.UserID}`).update({
              AccountRejected: true,
              AccountRejectedForterReason: 'Rejected by Forter',
            });
            this.navCtrl.navigateRoot('/validation');
          }
          resolve(res);
        })
        .catch((err) => {
          console.error('Forter API', { err });
          this.firestore.doc(`users/${this.UserID}`).set(
            {
              ForterInformation: {
                lastRequest: 'ERROR',
                errorMessage: err.message,
              },
            },
            { merge: true }
          );
        });
    });
  }

  validateLogin() {
    return this.apiService.post(`security/v1/user`, {
      forterId: this.latestForterToken.token,
      forterType: this.latestForterToken.type,
      deviceId: this.mobileId,
      appVersion: environment.version,
      login: 'email',
      app: 'sudster',
      platform: this.platformInfo,
    });
  }
}
