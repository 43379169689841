import {
  APP_INITIALIZER,
  ErrorHandler,
  isDevMode,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { AuthGuard } from './auth.guard';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import {
  AngularFireAnalyticsModule,
  CONFIG,
  ScreenTrackingService,
} from '@angular/fire/analytics';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import {
  AngularFireFunctionsModule,
  USE_EMULATOR as USE_FUNCTIONS_EMULATOR,
} from '@angular/fire/functions';
import {
  AngularFirePerformanceModule,
  PerformanceMonitoringService,
} from '@angular/fire/performance';
import {
  AngularFireRemoteConfigModule,
  DEFAULTS,
  SETTINGS,
} from '@angular/fire/remote-config';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { Smartlook } from '@awesome-cordova-plugins/smartlook/ngx';
import { SMS } from '@awesome-cordova-plugins/sms/ngx';
import { CookieService } from 'ngx-cookie-service';
import { NgxMaskModule } from 'ngx-mask';
import { NgxQrcodeStylingModule } from 'ngx-qrcode-styling';
import { environment } from 'src/environments/environment';
import { SwiperModule } from 'swiper/angular'; // Import SwiperModule
import { AppsFlyerService } from './_services/appsflyer.service';
import { ErrorHandlingService } from './_services/error-handling/error-handling.service';
import { IterableService } from './_services/iterable/iterable.service';
import {
  rollbarFactory,
  RollbarService,
} from './_services/rollbar/rollbar.service';

if (environment.emulator) {
  console.info('>>>>>>>>>>>>>>> Now using functions emulator 🚀');
}

export function forceServiceCreationFactory(
  iterableService: IterableService,
  appsFlyerService: AppsFlyerService
): () => Promise<void> {
  return () => {
    return Promise.resolve();
  };
}

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'md',
      scrollAssist: true,
    }),
    NgxMaskModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    AngularFirePerformanceModule,
    AngularFireRemoteConfigModule,
    NgxQrcodeStylingModule,
    AngularFirestoreModule.enablePersistence(),
    SwiperModule,
  ],
  providers: [
    Smartlook,
    StatusBar,
    SplashScreen,
    AuthGuard,
    ScreenTrackingService,
    SMS,
    IterableService,
    AppsFlyerService,
    {
      provide: APP_INITIALIZER,
      useFactory: forceServiceCreationFactory,
      deps: [IterableService, AppsFlyerService],
      multi: true,
    },
    {
      provide: CONFIG,
      useValue: {
        debug_mode: false,
      },
    },
    CookieService,
    { provide: Window, useValue: window },
    {
      provide: DEFAULTS,
      useValue: {
        ForterAPI: false,
        EnableInsurance: false,
        ConnectivityAlert: false,
      },
    },
    {
      provide: SETTINGS,
      useFactory: () =>
        isDevMode() ? { minimumFetchIntervalMillis: 10000 } : {},
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: ErrorHandlingService },
    { provide: RollbarService, useFactory: rollbarFactory },
    PerformanceMonitoringService,
    {
      provide: USE_FUNCTIONS_EMULATOR,
      useValue: environment.emulator ? ['localhost', 5001] : undefined,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
