import { Injectable, NgZone } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Subject, merge, timer } from 'rxjs';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';

export interface QueryParams {
  [key: string]: string;
}

const NAVIGATE_AFTER_DELAY = 2000;
const TRIGGER_IMMEDIATE_NAVIGATION_TIMER = 10;

@Injectable({
  providedIn: 'root',
})
export class DeepLinkService {
  private navigationSubject = new Subject<{
    route: string;
    queryParams: QueryParams;
    replaceUrl: boolean;
  }>();
  private navigationAllowGate = false;

  constructor(private router: Router, private zone: NgZone) {
    this.navigationSubject
      .pipe(
        tap(() => {
          this.navigationAllowGate = true;
        }),
        switchMap((navRequest) =>
          merge(
            this.router.events.pipe(
              filter(
                (event) =>
                  this.navigationAllowGate &&
                  (event instanceof NavigationEnd ||
                    event instanceof NavigationStart)
              )
            ),
            timer(TRIGGER_IMMEDIATE_NAVIGATION_TIMER)
          ).pipe(
            debounceTime(NAVIGATE_AFTER_DELAY),
            tap(() => {
              this.navigationAllowGate = false;
            }),
            map(() => navRequest)
          )
        )
      )
      .subscribe((navRequest) => {
        this.router.navigate([navRequest.route], {
          queryParams: navRequest.queryParams,
        });
      });

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        this.handleUrl(event.url);
      });
    });
  }

  public async handleUrl(url: string): Promise<void> {
    const openUrl = new URL(url);
    const params = openUrl.searchParams;

    const queryParams: QueryParams = {};

    params.forEach((value, key) => {
      queryParams[key] = value;
    });

    const routePaths = openUrl.pathname.split('/').filter((path) => path);
    // to support custom uri scheme poplinlp://page links
    if (openUrl.protocol === 'poplinlp:') {
      routePaths.push(openUrl.host);
    }

    if (routePaths?.length > 0) {
      const hasLevel1Route =
        this.router.config.findIndex((route) => route.path === routePaths[0]) >
        -1;
      if (hasLevel1Route) {
        const path = openUrl.pathname || routePaths[0];
        const navigationRequest = {
          route: path,
          queryParams: queryParams,
          replaceUrl: this.router.url === path,
        };

        this.navigationSubject.next(navigationRequest);
        return;
      }
    }
  }
}
