import { InjectionToken } from '@angular/core';

import Rollbar from 'rollbar';
import { environment } from 'src/environments/environment';
import packageJson from '../../../../package.json';

const rollbarConfig: Rollbar.Configuration = {
  accessToken: environment.rollbar.accessToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  captureEmail: true,
  captureIp: true,
  captureUsername: true,
  codeVersion: packageJson.version,
  ignoredMessages: ['Script error.'],
  // transform: function (payload: any) {
  //   if (
  //     payload &&
  //     payload.body &&
  //     payload.body.trace &&
  //     payload.body.trace.frames
  //   ) {
  //     const frames = payload.body.trace.frames;
  //     for (let i = 0; i < frames.length; i++) {
  //       if (frames[i].filename.indexOf('main.js') > -1) {
  //         payload.body.trace.frames[i].filename = 'file://main.js';
  //       }
  //       if (frames[i].filename.indexOf('vendor.js') > -1) {
  //         payload.body.trace.frames[i].filename = 'file://vendor.js';
  //       }
  //       if (frames[i].filename.indexOf('polyfills.js') > -1) {
  //         payload.body.trace.frames[i].filename = 'file://polyfills.js';
  //       }
  //       if (frames[i].filename.indexOf('common.js') > -1) {
  //         payload.body.trace.frames[i].filename = 'file://common.js';
  //       }
  //     }
  //   }
  // },
  payload: {
    environment: environment.production ? 'production' : 'development',
    client: {
      javascript: {
        code_version: '3.49.0',
        source_map_enabled: true,
        guess_uncaught_frames: true,
      },
    },
  },
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}
