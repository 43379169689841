import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'intro',
    loadChildren: () =>
      import('./_pages/intro/intro.module').then((m) => m.IntroPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'start',
    loadChildren: () =>
      import('./_pages/start/start.module').then((m) => m.StartPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./_pages/home/home.module').then((m) => m.HomePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./_pages/account/account.module').then((m) => m.AccountModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'help',
    loadChildren: () =>
      import('./_pages/help/help.module').then((m) => m.HelpPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'rank',
    loadChildren: () =>
      import('./_pages/rank/rank.module').then((m) => m.RankPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'unverified',
    loadChildren: () =>
      import('./_pages/unverified/unverified.module').then(
        (m) => m.UnverifiedPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'active',
    loadChildren: () =>
      import('./_pages/active/active.module').then((m) => m.ActivePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'money',
    loadChildren: () =>
      import('./_pages/money/money.module').then((m) => m.MoneyPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'rejected',
    loadChildren: () =>
      import('./_pages/rejected/rejected.module').then(
        (m) => m.RejectedPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'academy',
    loadChildren: () =>
      import('./_pages/academy/academy.module').then(
        (m) => m.AcademyPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'feedback',
    loadChildren: () =>
      import('./_pages/feedback/feedback.module').then(
        (m) => m.FeedbackPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'refercode',
    loadChildren: () =>
      import('./_pages/refercode/refercode.module').then(
        (m) => m.ReferCodePageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'refer',
    loadChildren: () =>
      import('./_pages/refer/refer.module').then((m) => m.ReferPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'adminlogin/:token',
    loadChildren: () =>
      import('./_pages/adminlogin/adminlogin.module').then(
        (m) => m.AdminloginPageModule
      ),
  },
  {
    path: 'validation',
    loadChildren: () =>
      import('./_pages/forter-validation/forter-validation.module').then(
        (m) => m.ForterValidationModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'background',
    loadChildren: () =>
      import('./_pages/verification/background-check/background.module').then(
        (m) => m.BackgroundCheckComponentModule
      ),
    canActivate: [AuthGuard],
  },

  {
    path: 'id-verification',
    loadChildren: () =>
      import('./_pages/id-verification/id-verification.module').then(
        (m) => m.IdVerificationPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'verification-success',
    loadChildren: () =>
      import('./_pages/verification-success/verification-success.module').then(
        (m) => m.VerificationSuccessPageModule
      ),
    canActivate: [AuthGuard],
  },

  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
