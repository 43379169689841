import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { first, take } from 'rxjs/operators';
import { SudsterData } from './_interfaces/sudster-data.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    public afAuth: AngularFireAuth,
    private route: ActivatedRoute,
    public firestore: AngularFirestore
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve) => {
      const userAuthObs = this.afAuth.user.pipe(take(1)).subscribe((user) => {
        let page = state.url.split(/[?|/]/g)[1];
        if (user) {
          this.firestore
            .doc<SudsterData>(`Sudsters/${user.uid}`)
            .valueChanges()
            .pipe(first())
            .subscribe((doc) => {
              if (doc.SignupStepNumber == 0) {
                // Logged In and Signed up
                if (page == 'start' || page == 'intro') {
                  this.router.navigate(['home']);
                  resolve(false);
                } else {
                  // Check Verification status
                  if (
                    page !== 'unverified' &&
                    doc.IDStatus !== 'verified' &&
                    !doc.AccountRejected
                  ) {
                    // Move to ID page
                    this.router.navigate(['unverified']);
                    resolve(false);
                  } else if (page != 'rejected' && doc.AccountRejected) {
                    this.router.navigate(['rejected']);
                    resolve(false);
                  } else {
                    resolve(true);
                  }
                }
              } else {
                if (page == 'start' || page == 'intro') {
                  resolve(true);
                } else {
                  this.router.navigate(['start']);
                  resolve(false);
                }
              }
            });
        } else {
          if (page == 'intro' || page == 'start') {
            resolve(true);
          } else {
            this.router.navigate(['intro']);
            resolve(false);
          }
        }
      });
    });
  }
}
