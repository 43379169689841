import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireRemoteConfig } from '@angular/fire/remote-config';
import { Smartlook } from '@awesome-cordova-plugins/smartlook/ngx';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { AlertController, Platform } from '@ionic/angular';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
  CheckrInformation,
  SudsterData,
} from 'src/app/_interfaces/sudster-data.interface';
import { environment } from '../environments/environment';
import { AppUpdateService } from './_services/app-update/app-update.service';
import { AuthidService } from './_services/authid.service';
import { SmartLookWebService } from './_services/smartlook/smartlook-web-service';
import { StatsigService } from './_services/statsig.service';
// import { register } from 'swiper/element/bundle';

// register();
const isStatusBarAvailable = Capacitor.isPluginAvailable('StatusBar');
if (isStatusBarAvailable) {
  StatusBar.setStyle({ style: Style.Light });
}

enum CheckrResponse {
  rejected = 'report.pre_adverse_action',
  engage = 'report.engaged',
  consider = 'consider',
  clear = 'clear',
  created = 'created',
}

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private platform: Platform,
    public afAuth: AngularFireAuth,
    public firestore: AngularFirestore,
    private alertController: AlertController,
    private authID: AuthidService,
    private statsigService: StatsigService,
    remoteConfig: AngularFireRemoteConfig,
    private appUpdate: AppUpdateService,
    private smartlookMobile: Smartlook,
    private smartlookWeb: SmartLookWebService
  ) {
    this.statsigService
      .checkGate(environment.statsig.flags.stripeIdv)
      .subscribe((value) => {
        this.stripeIdvFlag = value;
        this.statsigService
          .checkGate(environment.statsig.flags.checkrVerification)
          .subscribe((value) => {
            this.checkrVerificationFlag = value;
          });
      });
    this.initializeApp();
    remoteConfig.booleans.ConnectivityAlert.subscribe((value) => {
      this.ConnectivityAlert = value;
    });
  }
  ngOnDestroy(): void {
    this.statsigService.shutdown();
  }

  ConnectivityAlert: boolean = false;
  fetched = false;
  displayConnectivityAlert = false;

  stripeIdvFlag = false;
  checkrVerificationFlag = false;
  CheckrInformation: CheckrInformation;
  loading = true;

  ngOnInit() {}

  showConnectivityAlert(window: string) {
    if (window == 'intro' || window == 'start') {
      return;
    } else {
      this.displayConnectivityAlert = true;
      let message = `Unfortunately, your current network speed is causing a delay in loading this page.\n\n You can try:\n - Connecting to wi-fi\n - Closing other apps that are open \n - %Logging in via your browser& \n\nIf you need to upload a picture but can't, please take a picture and submit it to Poplin Support to upload for you. \n We've got your back.`;
      this.presentAlert(
        'Loading...',
        message
          .replace('%', `<a target=_blank href="${environment.webApp}">`)
          .replace('&', '</a>')
          .replaceAll('\n', '<br>')
      );
    }
  }

  initializeApp() {
    this.platform.ready().then(
      async () => {
        let windowUrl = window.location.href.split(/[?|/]/g)[3];
        setTimeout(() => {
          if (!this.fetched && this.ConnectivityAlert) {
            // check if information has been loaded, if not, call show alert function
            this.showConnectivityAlert(windowUrl);
          } else {
            return;
          }
        }, 5000);

        SplashScreen.hide();
        // Check ID Status
        await this.authID.activate().then((id: string) => {
          this.loading = false;
          if (id) {
            this.firestore
              .doc<SudsterData>(`Sudsters/${id}`)
              .valueChanges()
              .subscribe((doc) => {
                // set fetched to true so that the alert doesn't show
                this.fetched = true;
                if (this.displayConnectivityAlert) {
                  // if the alert is showing, dismiss it
                  setTimeout(() => {
                    this.alertController.dismiss();
                  }, 10000);
                }
              });
          }

          if (this.platform.is('cordova') && this.platform.is('ios')) {
            if (window['cordova'] && window['cordova']['plugins']['idfa']) {
              const idfaPlugin = window['cordova']['plugins']['idfa'];

              idfaPlugin.getInfo().then((info) => {
                if (!info.trackingLimited) {
                  // already allowed
                  return info.idfa || info.aaid;

                  // No previous intent to show tracking dialog
                } else if (
                  info.trackingPermission ===
                  idfaPlugin.TRACKING_PERMISSION_NOT_DETERMINED
                ) {
                  // request ios or android track permission
                  return idfaPlugin.requestPermission().then((result) => {
                    // authorized tracking
                    if (result === idfaPlugin.TRACKING_PERMISSION_AUTHORIZED) {
                      // fullfill the promise with the value
                      return idfaPlugin.getInfo().then((info) => {
                        return info.idfa || info.aaid;
                      });
                    }
                  });
                }
              });
            }
          }
        });
        this.statsigService
          .checkGate(environment.statsig.flags.smartlookRecordings)
          .subscribe((value) => {
            if (value) {
              if (Capacitor.isNativePlatform()) {
                this.smartlookMobile.setProjectKey({
                  key: environment.smartlook.key,
                });
                this.smartlookMobile.start();
              } else {
                this.smartlookWeb.init();
              }
            }
          });
      },
      (err) => {
        console.error('NO READY reason', { err });
      }
    );

    if (Capacitor.isNativePlatform()) {
      this.statsigService
        .checkGate(environment.statsig.flags.ForceUpdate)
        .subscribe((value) => {
          this.appUpdate.init(value);
        });
    }
  }

  async presentAlert(Title, Message): Promise<HTMLIonAlertElement> {
    const alert = await this.alertController.create({
      header: Title,
      message: Message,
      buttons: ['OK'],
    });
    await alert.present();
    return alert;
  }
}
