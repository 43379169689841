import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import Smartlook from 'smartlook-client';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SmartLookWebService {
  init(): boolean {
    return Smartlook.init(environment.smartlook.key);
  }

  userSetup(userId: string, fullName: string, userEmail: string): void {
    Smartlook.identify(userId, {
      name: fullName,
      email: userEmail,
    });

    Smartlook.properties({ platformType: Capacitor.getPlatform() });

    return;
  }

  stop(): boolean {
    return Smartlook.disable();
  }
}
